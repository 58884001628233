import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import FooterBranding from '../components/footer/FooterBranding'
import Aside from '../components/Aside'

const Apropos = (props) => (
  <Layout>

<div className="container custom-container">

<div className="content-wrapper title-page">
    <div className="content-wrapper-inner">
          <h1>JAIDE en quelques mots</h1>
          {/* <span>{props.location.hostname}{props.location.pathname}</span> */}
      </div>
</div>


  <div className="container-inner">
    <div className="content-wrapper">
    <div className="content-wrapper-inner">
        <div className="content">
        
          <img src="http://blog.associationjaide.fr/wp-content/uploads/2020/05/11-1.png" class="img-post" alt=""/>
          <p>
            « JAIDE» (J’Aime, j’Impacte, et je Donne de l’Espoir) est une association humanitaire et caritative avec des valeurs chrétiennes. Elle a été créée en septembre 2018 et est régie par la loi du 1er juillet 1901. 
          </p>
          <p>
            L’association JAIDE est entièrement composée de personnes bénévoles, motivées, dynamiques et désireuses de voir la vie des personnes en difficulté transformée et ainsi voir l’exclusion sociale reculer. <br/> 
           </p>
           <p>
            Notre association a vocation à contribuer de manière pratique aux besoins des populations locales notamment auprès des personnes isolées et/ ou rencontrant de grandes difficultés (personnes sans-domicile fixe, personnes incarcérées, les réfugiés, les jeunes en situation de précarité…), ainsi que les personnes malades et celles en situation de handicap.
           </p> 
           <p>
            Convaincue que l’entraide et la solidarité sont les clés du « vivre-ensemble » et de la baisse de la précarité locale, c’est en ce sens que l’Association JAIDE entend œuvrer.
          </p>
          <p>
          <b>« <i>On ne peut pas aider tout le monde, mais tout le monde peut aider </i>»</b> <br />
          <br/>
          <b>L’équipe JAIDE</b>
          </p>
        </div>

        <aside>
        <Aside/>
        </aside>
        
    </div>
    </div>
  </div>
</div>
    <FooterBranding/>
  </Layout>
)

export default Apropos
